<app-button-modal title="Print Label" [buttons]="buttons" [hideButtons]="!package" allowExit="true">
    <form #packageForm="ngForm">
        <div *ngIf="package" id="print-label">
            <h2 *ngIf="package.concept">
                <span>Let op:</span> Zodra dit label gedownload is, zal het pakket uit concept
                gehaald worden. Je kan het pakket dan niet meer aanpassen of verwijderen.
            </h2>
            <div class="packageInfo">
                <h2>
                    <a
                        href="https://www.viatim.nl/trackentrace-status/?tracktrace={{
                            package.tracktrace
                        }}&postcode={{ package.recipient.postcode }}"
                        target="_blank"
                    >
                        {{ package.tracktrace }}
                        <i class="far fa-share fa-xs" data-fa-transform="up-2"></i
                    ></a>
                </h2>
            </div>
            <div *ngIf="hasServicePoint" class="servicepoint-info">
                <i class="fad fa-3x fa-map-marker-alt markerIcon"></i><br />
                <h3>Servicepunt pakket naar:</h3>
                <ul>
                    <li>{{ package.service_point.name }}</li>
                    <li>
                        {{ package.service_point.streetname }}
                        {{ package.service_point.housenr
                        }}{{ package.service_point.houseNumberExtra }}
                    </li>
                    <li>
                        {{ package.service_point.postcode }}
                        {{ package.service_point.city }}
                    </li>
                </ul>
            </div>
            <div id="packageDetails">
                <div class="senderInfo">
                    <ul>
                        <li>
                            <h4>Verzender</h4>
                        </li>
                        <li *ngIf="package.sender.companyName; else senderFullname">
                            {{ package.sender.companyName }}
                        </li>
                        <ng-template #senderFullname>
                            <li>{{ package.sender | fullName }}</li>
                        </ng-template>
                        <li>
                            {{ package.sender.streetname }} {{ package.sender.houseNumber }}
                            {{ package.sender.houseNumberExtra }}
                        </li>
                        <li>{{ package.sender.addressExtra }}</li>
                        <li>{{ package.sender.postcode }}</li>
                        <li>{{ package.sender.city }}</li>
                        <li>
                            <a href="tel:{{ package.sender.phone }}"> {{ package.sender.phone }}</a>
                        </li>
                        <li>
                            <a href="mailto:{{ package.sender.email }}">{{
                                package.sender.email
                            }}</a>
                        </li>
                    </ul>
                </div>
                <div class="recipientInfo">
                    <ul>
                        <li>
                            <h4>Ontvanger</h4>
                        </li>
                        <li *ngIf="package.recipient.companyName; else recipientFullname">
                            {{ package.recipient.companyName }}
                        </li>
                        <ng-template #recipientFullname>
                            <li>{{ package.recipient | fullName }}</li>
                        </ng-template>
                        <li>
                            {{ package.recipient.streetname }}
                            {{ package.recipient.houseNumber }}
                            {{ package.recipient.houseNumberExtra }}
                        </li>
                        <li>{{ package.recipient.addressExtra }}</li>
                        <li>{{ package.recipient.postcode }}</li>
                        <li>{{ package.recipient.city }}</li>
                        <li>{{ package.recipient.country }}</li>
                        <li>
                            <a href="tel:{{ package.recipient.phone }}">
                                {{ package.recipient.phone }}</a
                            >
                        </li>
                        <li>
                            <a href="mailto:{{ package.recipient.email }}">
                                {{ package.recipient.email }}</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </form>
</app-button-modal>
