import { Location } from "@angular/common";
import { Store } from "@ngrx/store";
import { Observable, forkJoin } from "rxjs";
import { Component } from "@angular/core";
import { first } from "rxjs/operators";
import { BackendService } from "../../services/backend.service";
import { refreshPackages } from "../../store/actions/packages.actions";
import { addMessage } from "../../store/actions/message.actions";
import { clearSelectedPackages } from "../../store/actions/selected-packages.actions";
import { Message } from "../../models/message";

@Component({
    selector: "app-bulk-delete-modal",
    templateUrl: "./bulk-delete-modal.component.html",
})
export class BulkDeleteModalComponent {
    selectedPackages$: Observable<string[]> = this.store.select((state) => state.selectedPackages);

    constructor(
        private backend: BackendService,
        private location: Location,
        private store: Store<{ selectedPackages: string[] }>,
    ) {}

    public buttons: ButtonModal.Buttons = [
        {
            text: "Verwijder",
            onClick: () => {
                this.deletePackages();
            },
        },
    ];

    deletePackages() {
        this.selectedPackages$.pipe(first()).subscribe((selectedPackages) => {
            const deletes = selectedPackages.map(this.backend.deletePackage, this.backend);
            forkJoin(deletes).subscribe(
                () => {
                    const message = Message.createSuccessMessage(
                        "Pakketten verwijderd",
                        `Er zijn ${selectedPackages.length} pakketen verwijderd`,
                    );
                    this.closeModal(message);
                },
                () => {
                    const message = Message.createErrorMessage(
                        "Verwijderen (gedeeltelijk) mislukt",
                        "Het verwijderen van een of meerdere pakketen is mislukt",
                    );
                    this.closeModal(message);
                },
            );
        });
    }

    closeModal(message: Message = null) {
        if (message) {
            this.store.dispatch(clearSelectedPackages());
            this.store.dispatch(addMessage({ message }));
        }
        this.store.dispatch(refreshPackages());
        this.location.back();
    }
}
