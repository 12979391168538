// import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export type TransporterInvoiceItemFilter = {
    externalId?: number;
    externalInvoiceId?: string;
    has_price?: boolean;
    start?: number;
    limit?: number;
    carrierCode?: string;
    externalPriceTypeId?: number;
    ownerId?: number;
};

@Injectable()
export class InvoicingService {
    // constructor(private http: HttpClient) {}
}
