<app-button-modal title="Retour Labels" [buttons]="buttons" [hideButtons]="busy" allowExit="true">
    <form [formGroup]="form">
        <div class="form-group">
            <label for="count">Aantal labels</label>
            <input formControlName="count" id="count" class="form-control" type="number" />
        </div>
        <div class="form-group">
            <label for="email">Email adres</label>
            <input formControlName="email" id="email" class="form-control" type="email" />
        </div>

        <div class="form-group">
            <div class="form-check">
                <input
                    type="radio"
                    value="MAILBOX"
                    formControlName="parcelType"
                    name="parcelType"
                    class="form-check-input"
                />
                <label for="parcelType1">Brievenbus</label>
            </div>
            <div class="form-check">
                <input
                    type="radio"
                    value="NORMAL"
                    formControlName="parcelType"
                    name="parcelType"
                    class="form-check-input"
                />
                <label for="parcelType2">Pakket</label>
            </div>
        </div>
    </form>
</app-button-modal>
