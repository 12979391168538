import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import { BackendService } from "../../../services/backend.service";
import { E_USER_TYPES } from "../../../helpers/constants";
import { Package } from "../../../models/package.model";

@Component({
    selector: "app-viatim-statistics-modal",
    templateUrl: "./viatim-statistics-modal.component.html",
    styleUrls: ["./viatim-statistics-modal.component.scss"],
})
export class ViatimStatisticsModalComponent implements OnInit {
    packages: Package[];

    showPackages: boolean = false;

    private statsTotal: any;

    stats: any;

    statistics: any = {};

    allTime: string = "1970-1-1";

    today: any = "";

    lastWeek: any = "";

    // private packagesPerWebshop : any = [];

    constructor(
        private auth: AuthService,
        private backend: BackendService,
        private router: Router,
    ) {}

    buttons: ButtonModal.Buttons = [
        {
            text: "Refresh",
            onClick: () => this.refresh(),
        },
    ];

    ngOnInit() {
        this.getDate();
        this.getStats();
    }

    get canSeePackages() {
        return (
            this.auth.getAccountRole() === E_USER_TYPES.VIADC ||
            this.auth.getAccountRole() === E_USER_TYPES.MODERATOR ||
            this.auth.getAccountRole() === E_USER_TYPES.GLOBAL_ADMIN
        );
    }

    getDate() {
        // Today
        const today = new Date();
        // Adds 1 to month (Jan is 0!)
        const currentMonth = today.getMonth() + 1;

        this.today = `${today.getFullYear()}-${currentMonth}-${today.getDate()}`;
        // console.log("today", this.today);

        // One week ago
        const oneWeekAgo = new Date();
        const oneWeek = 7;

        oneWeekAgo.setDate(oneWeekAgo.getDate() - oneWeek);

        // Maand
        const oneWeekAgoMonth = oneWeekAgo.getMonth() + 1;

        // Dag
        this.lastWeek = `${oneWeekAgo.getFullYear()}-${oneWeekAgoMonth}-${oneWeekAgo.getDate()}`;
    }

    getStats() {
        // Packages not picked up
        if (
            !(
                this.auth.getAccountRole() !== E_USER_TYPES.VIADC &&
                this.auth.getAccountRole() !== E_USER_TYPES.MODERATOR &&
                this.auth.getAccountRole() !== E_USER_TYPES.GLOBAL_ADMIN
            )
        ) {
            this.checkStatsCall(
                "noPickupAllTime",
                `status=0&concept=false&dateStart=${this.allTime}`,
            );
        }
        this.checkStatsCall(
            "noPickupLastWeek",
            `status=0&concept=false&dateStart=${this.lastWeek}`,
        );
        this.checkStatsCall("noPickupToday", `status=0&concept=false&dateStart=${this.today}`);

        // Packages not delivered
        if (
            !(
                this.auth.getAccountRole() !== E_USER_TYPES.VIADC &&
                this.auth.getAccountRole() !== E_USER_TYPES.MODERATOR &&
                this.auth.getAccountRole() !== E_USER_TYPES.GLOBAL_ADMIN
            )
        ) {
            this.checkStatsCall(
                "notDeliveredAllTime",
                `status=1,2,3,5,6,7,8,9,10&concept=false&dateStart=${this.allTime}`,
            );
        }
        this.checkStatsCall(
            "notDeliveredLastWeek",
            `status=1,2,3,5,6,7,8,9,10&concept=false&dateStart=${this.lastWeek}`,
        );
        this.checkStatsCall(
            "notDeliveredToday",
            `status=1,2,3,5,6,7,8,9,10&concept=false&dateStart=${this.today}`,
        );
    }

    checkStatsCall(varName: string, query: string) {
        this.backend.getPackageStats(query).subscribe(
            (d) => {
                this.statsTotal = (d as any).total;
                this.statistics[varName] = this.statsTotal;
            },
            () => {
                // console.log('Error:', e);
            },
        );
    }

    refresh() {
        this.ngOnInit();
    }

    getPackages(query: string, date: string) {
        // console.log("ingelogd als", this.auth.getAccountRole());

        if (
            this.auth.getAccountRole() === E_USER_TYPES.VIADC ||
            this.auth.getAccountRole() === E_USER_TYPES.MODERATOR ||
            this.auth.getAccountRole() === E_USER_TYPES.GLOBAL_ADMIN
        ) {
            this.getCustomStats(query + date);
        }

        this.backend.getPackages(query + date).subscribe((result) => {
            this.packages = result;

            // console.log('Packages success:', this.packages);
            this.showPackages = true;
            // console.log(this.showPackages);
            // this.countPackageSenders(this.packages);
        });
    }

    detailPage(tracktrace: string) {
        this.router.navigateByUrl(`/packages/${tracktrace}`);
    }

    getCustomStats(query: string) {
        this.backend.getPackageStats(query).subscribe(
            (d) => {
                this.stats = (d as any).data;
            },
            () => {
                // console.log('Error:', e);
            },
        );
    }
}
