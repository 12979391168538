<app-button-modal title="Fijne feestdagen!" allowExit="true">
    <section *ngIf="currentUser$ | async as currentUser">
        <div class="image"></div>
        <div class="text">
            <h1>
                Beste <span>{{ currentUser.getFullName() }}</span
                >,
            </h1>
            <p>Wij van ViaTim wensen je hele fijne feestdagen en een fantastisch 2023!</p>
            <p>Een feestelijke groet,</p>
            <p>Het ViaTim Team</p>
        </div>
    </section>
</app-button-modal>
