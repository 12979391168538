/* eslint-disable max-classes-per-file */

import * as Sentry from "@sentry/browser";
import { NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { ControlPanelModule } from "./control-panel/control-panel.module";
import { PackagesModule } from "./packages/packages.module";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { StatisticsComponent } from "./statistics/statistics.component";

// Contacts
import { ContactsModule } from "./contacts/contacts.module";
import { ContactsComponent } from "./contacts/contacts.component";

import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";

import { CreateUserComponent } from "./create-user/create-user.component";
import { ViatimNewsModalComponent } from "./dashboard/modals/viatim-news-modal/viatim-news-modal.component";
import { SupportComponent } from "./support/support.component";
import { ViatimPatchNotesModalComponent } from "./dashboard/modals/viatim-patch-notes-modal/viatim-patch-notes-modal.component";
import { ViatimModalComponent } from "./dashboard/modals/viatim-modal/viatim-modal.component";
import { ViatimStatisticsModalComponent } from "./dashboard/modals/viatim-statistics-modal/viatim-statistics-modal.component";

import { CreateUserCreateViadcComponent } from "./create-user/create-user-create-viadc/create-user-create-viadc.component";
import { CreateUserCreateViabodeComponent } from "./create-user/create-user-create-viabode/create-user-create-viabode.component";

// Create users revamp
import { serviceProviders } from "./services";

import { BulkStatusModalComponent } from "./dashboard/modals/bulk-status-modal/bulk-status-modal.component";
import { OldInvoicingExportModalComponent } from "./dashboard/modals/old-invoicing-export-modal/old-invoicing-export-modal.component";
import { ReturnLabelsModalComponent } from "./dashboard/modals/return-labels-modal/return-labels-modal.component";
import { environment } from "../environments/environment";
import { reducers, metaReducers } from "./store/reducers";
import { effects } from "./store/effects";
import { LoginComponent } from "./log-in/login.component";
import { LoginEntryComponent } from "./log-in/login-entry/login-entry.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ForgotPasswordFormComponent } from "./forgot-password/forgot-password-form/forgot-password-form.component";
import { ForgotPasswordSuccessComponent } from "./forgot-password-success/forgot-password-success.component";
import { PasswordResetFormComponent } from "./password-reset/password-reset-form/password-reset-form.component";
import { PasswordResetComponent } from "./password-reset/password-reset.component";
import { PasswordResetSuccessComponent } from "./password-reset-success/password-reset-success.component";
import { SharedModule } from "./shared/shared.module";
import { WebshopsModule } from "./webshops/webshops.module";

import { AccountManagementComponent } from "./account-management/account-management.component";
import { AccountManagementEditComponent } from "./account-management/account-management-edit/account-management-edit.component";
import { pipes } from "./helpers/pipes";
import { NgbDateNLParserFormatter } from "./helpers/dutchDateHelper";
import { SyncLabelsComponent } from "./dashboard/modals/sync-labels/sync-labels.component";

import nl from "@angular/common/locales/nl";
import { registerLocaleData } from "@angular/common";
registerLocaleData(nl);

Sentry.init({
    dsn: "https://05f4f7a7c0f1439f9c8f6fb11b733517@sentry.io/1501582",
    environment: environment.envName,
    release: environment.version,
    tracesSampleRate: 0.1,
    beforeSend: (event, hint) => {
        // eslint-disable-next-line no-console
        console.error("Sentry", hint.originalException || hint.syntheticException);
        if (environment.envName === "dev") {
            return null; // this drops the event and nothing will be send to sentry
        }
        return event;
    },
});

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        PageNotFoundComponent,
        UnauthorizedComponent,
        CreateUserComponent,
        ViatimNewsModalComponent,
        SupportComponent,
        ContactsComponent,
        ViatimPatchNotesModalComponent,
        ViatimStatisticsModalComponent,
        StatisticsComponent,
        ViatimModalComponent,
        CreateUserCreateViadcComponent,
        CreateUserCreateViabodeComponent,
        BulkStatusModalComponent,
        LoginComponent,
        LoginEntryComponent,
        ForgotPasswordComponent,
        ForgotPasswordFormComponent,
        ForgotPasswordSuccessComponent,
        PasswordResetSuccessComponent,
        PasswordResetFormComponent,
        PasswordResetComponent,
        AccountManagementComponent,
        AccountManagementEditComponent,
        OldInvoicingExportModalComponent,
        ReturnLabelsModalComponent,
        SyncLabelsComponent,
    ],
    imports: [
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        HttpClientModule,
        ControlPanelModule,
        PackagesModule,
        WebshopsModule,
        ContactsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        EffectsModule.forRoot(effects),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        NgbModule,
    ],
    providers: [
        ...serviceProviders,
        ...pipes,
        {
            provide: NgbDateParserFormatter,
            useFactory: () => new NgbDateNLParserFormatter("DD-MM-YYYY"),
        },
        { provide: LOCALE_ID, useValue: "nl" },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
