<app-cp-page>
    <div class="external-errors" *ngIf="validationErrors.length !== 0">
        <div>
            <h2>Dit pakket bevat externe validatiefouten</h2>
            <div class="row" *ngFor="let item of validationErrors">
                {{ item.error }} voor veld(en) : {{ item.fields.join(",") }}
            </div>
        </div>
    </div>
    <div class="create-package-container">
        <div class="create-package-container__form">
            <!-- Recipient information -->
            <div class="left-column">
                <div class="sender-info" *ngIf="sender">
                    <div class="row section-header">
                        <h1>Verzender</h1>
                        <select [(ngModel)]="sender" (change)="changeSender()">
                            <option
                                *ngFor="let addressOption of addressOptions"
                                [ngValue]="addressOption"
                            >
                                {{ addressOption.name }}
                            </option>
                        </select>
                    </div>
                    <app-address-form
                        *ngIf="!sender.id"
                        [availableCountries]="availableCountries"
                        [(address)]="package.sender"
                        [addressValidationErrors]="addressValidationErrors"
                        (errors)="(errors)"
                        [isPostNL]="isPostNL"
                        (onValidatedFieldsChange)="checkValidations()"
                    >
                    </app-address-form>
                </div>

                <div class="recipient-info" *ngIf="recipient">
                    <div class="row section-header">
                        <h1>Ontvanger</h1>
                        <select [(ngModel)]="recipient" (change)="changeRecipient()">
                            <option
                                *ngFor="let addressOption of addressOptions"
                                [ngValue]="addressOption"
                            >
                                {{ addressOption.name }}
                            </option>
                        </select>
                    </div>
                    <app-address-form
                        *ngIf="!recipient.id"
                        [availableCountries]="availableCountries"
                        (onValidatedFieldsChange)="checkValidations()"
                        [(address)]="package.recipient"
                        [addressValidationErrors]="addressValidationErrors"
                        (errors)="(errors)"
                        [hasServicePoint]="hasServicePoint"
                        (onCapabilityChange)="updateCapabilities()"
                    >
                    </app-address-form>
                </div>
            </div>
            <!-- Package Information -->
            <div class="package-info">
                <div class="row">
                    <h1>Pakket Informatie</h1>
                </div>
                <div class="row">
                    <span class="field-name">Vervoerder<i class="fas fa-asterisk"></i></span>
                    <div class="field-input sender">
                        <div *ngFor="let external of availableCarriers">
                            <button
                                class="external-button"
                                [class.selected]="package.external_id === external.value"
                                (click)="updateExternal(external.value)"
                            >
                                <app-external-icon [external]="external.value"></app-external-icon>
                            </button>
                        </div>
                    </div>

                    <div class="row error-row" *ngIf="hasError('external')">
                        <div class="error-section-input">
                            <span
                                class="errorMessageInput"
                                *ngFor="let item of getErrors('external')"
                                >{{ item | errorToText : "external" }}</span
                            >
                        </div>
                    </div>
                </div>

                <div class="row servicepoint" *ngIf="package.recipient && showServicePoint">
                    <span class="field-name">Bezorgen bij servicepunt?</span>
                    <div class="field-input servicepoint__content">
                        <button
                            *ngIf="!hasServicePoint"
                            [disabled]="!package.external_id"
                            class="btn servicepoint__button"
                            (click)="chooseServiceLocation()"
                        >
                            <i class="fad fa-lg fa-map-marker-alt"></i> Kies een servicepunt
                        </button>
                        <div
                            class="servicepoint__data"
                            *ngIf="hasServicePoint"
                            (click)="chooseServiceLocation()"
                        >
                            <div class="servicepoint__left">
                                <i class="fad fa-2x fa-map-marker-alt marker-icon"></i>
                                <span class="service-point-distance"
                                    >{{ package.service_point.distance }} meter</span
                                >
                            </div>
                            <div class="servicepoint__right">
                                <div class="servicepoint-actions" (click)="clearServicePoint()">
                                    <i
                                        class="fad fa-lg fa-times-circle delete-service-point-icon"
                                    ></i>
                                </div>
                                <ul>
                                    <li>{{ package.service_point.name }}</li>
                                    <li>
                                        {{ package.service_point.streetname }}
                                        {{ package.service_point.housenr }}
                                    </li>
                                    <li>
                                        {{ package.service_point.postcode }},
                                        {{ package.service_point.city }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row tempHidden">
                    <span class="align-top">Gewicht in kilo</span>
                    <input
                        type="number"
                        min="0"
                        class="text-input"
                        placeholder="5"
                        [(ngModel)]="package.weight"
                        name="weight"
                    />
                </div>

                <div class="row">
                    <span class="field-name">Pakket Type<i class="fas fa-asterisk"></i></span>
                    <div class="field-input package-information">
                        <input
                            class="package-information__radio-btn"
                            type="radio"
                            [(ngModel)]="package.type"
                            id="type0"
                            name="packageType"
                            [value]="5"
                            required
                        />
                        <label for="type0">0 - 15kg</label><br />
                        <input
                            class="package-information__radio-btn"
                            type="radio"
                            [(ngModel)]="package.type"
                            id="type1"
                            name="packageType"
                            [value]="6"
                            required
                        />
                        <label for="type1">15 - 30kg</label><br />
                        <input
                            class="package-information__radio-btn"
                            [attr.disabled]="hasServicePoint ? 'disabled' : null"
                            *ngIf="!isForeignPackage || canUseForeignMailbox"
                            type="radio"
                            [(ngModel)]="package.type"
                            id="type2"
                            name="packageType"
                            [value]="2"
                            required
                        />
                        <label
                            *ngIf="!isForeignPackage || canUseForeignMailbox"
                            for="type2"
                            [ngClass]="{ 'disabled-packagetype': hasServicePoint }"
                            >Brievenbuspakket</label
                        ><br />
                        <input
                            class="package-information__radio-btn"
                            [attr.disabled]="hasServicePoint ? 'disabled' : null"
                            *ngIf="!isForeignPackage"
                            type="radio"
                            [(ngModel)]="package.type"
                            id="type3"
                            name="packageType"
                            [value]="11"
                            required
                        />
                        <label
                            *ngIf="!isForeignPackage"
                            for="type3"
                            [ngClass]="{ 'disabled-packagetype': hasServicePoint }"
                            >Envelop</label
                        ><br />
                    </div>
                </div>
                <div class="row error-row" *ngIf="hasError('packageType')">
                    <div class="error-section-input">
                        <span
                            class="errorMessageInput"
                            *ngFor="let item of getErrors('packageType')"
                            >{{ item | errorToText : "type" }}</span
                        >
                    </div>
                </div>

                <div class="row tempHidden">
                    <span class="align-top">Lengte, breedte, hoogte</span>
                    <input
                        type="number"
                        min="0"
                        class="text-input input-medium"
                        placeholder="Lengte"
                        [(ngModel)]="package.length"
                        name="length"
                    />
                    <input
                        type="number"
                        min="0"
                        class="text-input input-medium"
                        placeholder="Breedte"
                        [(ngModel)]="package.width"
                        name="width"
                    />
                    <input
                        type="number"
                        min="0"
                        class="text-input input-medium"
                        placeholder="Hoogte"
                        [(ngModel)]="package.height"
                        name="height"
                    />
                </div>

                <div class="row tempHidden">
                    <span class="align-top">Waarde (in euro's)</span>
                    <input
                        type="number"
                        min="0"
                        class="text-input"
                        placeholder="16.50"
                        [(ngModel)]="package.value"
                        name="value"
                    />
                </div>

                <div class="row">
                    <span class="field-name">Pakket opties</span>
                    <div class="package-options">
                        <div
                            class="package-option"
                            [ngClass]="{
                                disable: !currentCapabilities.includes('PROOF_OF_DELIVERY'),
                                selected: package.proof_of_delivery
                            }"
                            (click)="package.proof_of_delivery = !package.proof_of_delivery"
                        >
                            <div class="package-option__btn">
                                <i class="package-option__btn-icon fas fa-signature"></i>
                            </div>
                            <div class="package-option__text">
                                <span>Handtekening bij ontvangst</span>
                            </div>
                        </div>

                        <div
                            class="package-option"
                            [ngClass]="{
                                disable: !currentCapabilities.includes('INSURANCE'),
                                selected: package.insured
                            }"
                            (click)="package.insured = !package.insured"
                        >
                            <div class="package-option__btn">
                                <i class="package-option__btn-icon fas fa-shield"></i>
                            </div>
                            <div class="package-option__text">
                                <span>Verzekerd (t.w.v. € 250,-)</span>
                            </div>
                        </div>

                        <div
                            class="package-option"
                            [ngClass]="{
                                disable: !currentCapabilities.includes('ID_CHECK'),
                                selected: package.id_check
                            }"
                            (click)="package.id_check = !package.id_check"
                        >
                            <div class="package-option__btn">
                                <i class="package-option__btn-icon fas fa-passport"></i>
                            </div>
                            <div class="package-option__text">
                                <span>18+ Check</span>
                            </div>
                        </div>

                        <div
                            class="package-option"
                            [ngClass]="{
                                disable: !currentCapabilities.includes('RECIPIENT_ONLY'),
                                selected: package.no_neighbours
                            }"
                            (click)="package.no_neighbours = !package.no_neighbours"
                        >
                            <div class="package-option__btn">
                                <i class="package-option__btn-icon fas fa-user-times"></i>
                            </div>
                            <div class="package-option__text">
                                <span>Niet bij de buren</span>
                            </div>
                        </div>

                        <div
                            class="package-option"
                            [ngClass]="{
                                disable: !currentCapabilities.includes('SAME_DAY'),
                                selected: package.same_day
                            }"
                            (click)="package.same_day = !package.same_day"
                        >
                            <div class="package-option__btn">
                                <i class="package-option__btn-icon fas fa-calendar-check"></i>
                            </div>
                            <div class="package-option__text">
                                <span>Same-day delivery</span>
                            </div>
                        </div>

                        <div
                            class="package-option"
                            [ngClass]="{
                                disable: !currentCapabilities.includes('EVENING_DELIVERY'),
                                selected: package.evening_delivery
                            }"
                            (click)="package.evening_delivery = !package.evening_delivery"
                        >
                            <div class="package-option__btn">
                                <i class="package-option__btn-icon far fa-sunset"></i>
                            </div>
                            <div class="package-option__text">
                                <span>Avond bezorging</span>
                            </div>
                        </div>

                        <div
                            class="package-option"
                            [ngClass]="{
                                disable: !currentCapabilities.includes('PREMIUM'),
                                selected: package.premium
                            }"
                            (click)="package.premium = !package.premium"
                        >
                            <div class="package-option__btn">
                                <i class="package-option__btn-icon far fa-stars"></i>
                            </div>
                            <div class="package-option__text">
                                <span>Luxe bezorging</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row error-row" *ngIf="hasError('options')">
                    <div class="error-section-input">
                        <span class="errorMessageInput" *ngFor="let item of getErrors('options')">{{
                            item | errorToText : "options"
                        }}</span>
                    </div>
                </div>

                <div class="row">
                    <span>Kenmerk</span>
                    <input
                        type="text"
                        class="text-input"
                        placeholder="Kenmerk"
                        [(ngModel)]="package.mark"
                        name="mark"
                        maxlength="30"
                    />
                </div>
                <div class="row error-row" *ngIf="hasError('mark')">
                    <span></span>
                    <div class="error-section-input">
                        <span class="errorMessageInput" *ngFor="let item of getErrors('mark')">{{
                            item | errorToText : "mark"
                        }}</span>
                    </div>
                </div>

                <div class="row">
                    <span class="align-top">Opmerking</span>
                    <textarea
                        class="text-area"
                        placeholder="Opmerking"
                        [(ngModel)]="package.instructions"
                        name="instructions"
                        maxlength="144"
                    ></textarea>
                </div>
                <div class="row error-row" *ngIf="hasError('instructions')">
                    <span></span>
                    <div class="error-section-input">
                        <span
                            class="errorMessageInput"
                            *ngFor="let item of getErrors('instructions')"
                            >{{ item | errorToText : "instructions" }}</span
                        >
                    </div>
                </div>

                <div class="row submit">
                    <button *ngIf="package.tracktrace" class="btn add" (click)="editPackage()">
                        <span><i class="submit__icon fas fa-edit fa-lg"></i></span> Pakket Aanpassen
                    </button>
                    <button *ngIf="!package.tracktrace" class="btn add" (click)="save()">
                        <span><i class="submit__icon fa fa-plus fa-lg"></i></span> Pakket aanmaken
                    </button>
                    <button *ngIf="!package.tracktrace" class="btn add" (click)="saveAndAdd()">
                        <span><i class="submit__icon fas fa-layer-plus fa-lg"></i></span>
                        Pakket aanmaken & Nieuw
                    </button>
                </div>
            </div>
        </div>
    </div>
    <app-button-modal
        *ngIf="chooseServicePoint"
        title="Selecteer servicepunt"
        (alternativeCloseModal)="closeDialog()"
        allowExit="true"
    >
        <app-choose-parcelpoint
            [postcode]="package.recipient.postcode"
            [houseNumber]="package.recipient.houseNumber"
            [external]="package.external_id"
            [country]="package.recipient.country"
            (servicePointChange)="setServicePoint($event)"
        >
        </app-choose-parcelpoint>
    </app-button-modal>
</app-cp-page>
