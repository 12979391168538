import { ActionReducerMap, MetaReducer } from "@ngrx/store";

import * as messageReducer from "./message.reducer";
import * as sessionReducer from "./session.reducer";
import * as currentUserReducer from "./current-user.reducer";
import * as packageFilterReducer from "./package-filter.reducer";
import * as selectedPackagesReducer from "./selected-packages.reducer";
import * as packagesReducer from "./packages.reducer";
import * as busyReducer from "./busy.reducer";

import { environment } from "../../../environments/environment";
import { Message } from "../../models/message";
import { User } from "../../models/user.model";
import { Package } from "../../models/package.model";
import { PackageFilter } from "../../models/packageFilter";
import { TablePage } from "../../models/tablePage.model";

export interface State {
    messages: Message[];
    session: sessionReducer.SessionState;
    currentUser: User;
    packageFilter: PackageFilter;
    selectedPackages: string[];
    packages: TablePage<Package>;
    busy: boolean;
}

export const reducers: ActionReducerMap<State> = {
    session: sessionReducer.reducer,
    messages: messageReducer.reducer,
    currentUser: currentUserReducer.reducer,
    packageFilter: packageFilterReducer.reducer,
    selectedPackages: selectedPackagesReducer.reducer,
    packages: packagesReducer.reducer,
    busy: busyReducer.reducer,
};

/**
 * Layout Reducers
 */
// export const getMessageState = createFeatureSelector<messageReducer.Message[]>("message");

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
