import { isInteger, toInteger } from "lodash-es";

export enum E_EXTERNAL_PARTIES {
    VIATIM = 1,
    DHL = 2,
    MYPARCEL = 3,
    DPD = 4,
    FEDEX = 5,
    RJP = 6,
    PARCELPRO = 7,
    ASCENDIA = 8,
    SBS = 9,
    POSTNL = 10,
    BUDBEE = 11,
    POSTNL_BRIEF = 12,
    CYCLOON = 13,
    BPOST = 14,
    DHL_ECOM = 15,
    DHL_4Y1 = 16,
    DHL_4Y2 = 17,
    COLIS_PRIVE = 18,
    DHL_DE = 19,
    GLS = 20,
}

export const SHIPPING_COMPANIES = [
    { value: E_EXTERNAL_PARTIES.VIATIM, name: "ViaTim", code: "VIATIM" },
    { value: E_EXTERNAL_PARTIES.DHL, name: "DHL", code: "DHL" },
    { value: E_EXTERNAL_PARTIES.PARCELPRO, name: "Post NL", code: "PARCELPRO" },
    { value: E_EXTERNAL_PARTIES.MYPARCEL, name: "Post NL", code: "MYPARCEL" },
    { value: E_EXTERNAL_PARTIES.CYCLOON, name: "Cycloon", code: "CYCLOON" },
    { value: E_EXTERNAL_PARTIES.BPOST, name: "BPost", code: "BPOST" },
    { value: E_EXTERNAL_PARTIES.DHL_ECOM, name: "DHL ECOM", code: "DHL_ECOM" },
    { value: E_EXTERNAL_PARTIES.DHL_4Y1, name: "DHL 4 You 1", code: "DHL_4Y1" },
    { value: E_EXTERNAL_PARTIES.DHL_4Y2, name: "DHL 4 You 2", code: "DHL_4Y2" },
    { value: E_EXTERNAL_PARTIES.COLIS_PRIVE, name: "Colis Prive", code: "COLIS_PRIVE" },
    { value: E_EXTERNAL_PARTIES.DHL_DE, name: "DHL DE", code: "DHL_DE" },
    { value: E_EXTERNAL_PARTIES.GLS, name: "GLS", code: "GLS" },
] as const;

export const CARRIER_CODES = [
    "DHL",
    "POSTNL",
    "DHL_ECOM",
    "DHL_4Y1",
    "DHL_4Y2",
    "CYCLOON",
    "BPOST",
    "DPD",
    "COLIS_PRIVE",
    "DHL_DE",
    "GLS",
] as const;

export type CarrierCode = (typeof CARRIER_CODES)[number];

export const externalIdToName = (externalId: E_EXTERNAL_PARTIES) => {
    const transporter = SHIPPING_COMPANIES.find((sc) => sc.value === externalId);
    if (transporter) {
        return transporter.name;
    }
    return "Onbekend";
};

export const externalIdToCode = (externalId: string | number) => {
    if (!externalId) {
        return "UNKNOWN";
    }
    let value: number;
    if (typeof externalId === "string" && isInteger(Number.parseInt(externalId, 10))) {
        value = toInteger(externalId);
    } else if (typeof externalId === "number") {
        value = externalId;
    } else {
        return "UNKNOWN";
    }
    const transporter = SHIPPING_COMPANIES.find((sc) => sc.value === value);
    if (transporter) {
        return transporter.code;
    }
    return "UNKNOWN";
};
