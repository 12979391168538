import { Store } from "@ngrx/store";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { isString } from "lodash-es";
import { Subscription } from "rxjs";
import { BackendService } from "../../services/backend.service";
import { AuthService } from "../../services/auth.service";
import { DataService } from "../../services/data.service";
import { Package } from "../../models/package.model";
import { E_USER_TYPES } from "../../helpers/constants";
import { Message } from "../../models/message";
import { addMessage } from "../../store/actions/message.actions";
import { refreshPackages } from "../../store/actions/packages.actions";

@Component({
    selector: "app-delete-package-modal",
    templateUrl: "./delete-package-modal.component.html",
    styleUrls: ["./delete-package-modal.component.scss"],
})
export class DeletePackageModalComponent implements OnInit, OnDestroy {
    private tracktrace: string;

    private sub: Subscription;

    package: Package;

    constructor(
        private auth: AuthService,
        private backend: BackendService,
        private dataService: DataService,
        private store: Store,
        private location: Location,
    ) {}

    buttons: ButtonModal.Buttons = [
        {
            text: "Verwijder",
            onClick: () => this.onSubmit(),
        },
    ];

    ngOnInit() {
        this.sub = this.dataService.currentMessage.subscribe((message) => {
            if (message.recipient !== "delete") {
                return;
            }
            this.tracktrace = message.content;
            if (!isString(this.tracktrace)) {
                this.location.back();
                return;
            }
            this.backend.getPackageByTrackTrace(this.tracktrace).subscribe(
                (result) => {
                    this.package = result;
                    if (
                        this.auth.getAccountRole() !== E_USER_TYPES.VIADC &&
                        this.auth.getAccountRole() !== E_USER_TYPES.MODERATOR &&
                        this.auth.getAccountRole() !== E_USER_TYPES.GLOBAL_ADMIN
                    ) {
                        if (this.package.concept === false) {
                            const errorMessage = Message.createErrorMessage(
                                "Verwijderen niet toegestaan",
                                "Het is niet mogelijk om pakketten die al geprint zijn te verwijderen",
                            );
                            this.closeModal(errorMessage);
                        }
                    }
                },
                () => {
                    const errorMessage = Message.createErrorMessage(
                        "Fout bij verwijderen",
                        "Er is iets misgegaan bij het verwijderen. Probeer het later nog eens.",
                    );
                    this.closeModal(errorMessage);
                },
            );
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    closeModal(message: Message = null) {
        if (message) {
            this.store.dispatch(addMessage({ message }));
        }
        this.store.dispatch(refreshPackages());
        this.location.back();
    }

    onSubmit() {
        this.backend.deletePackage(this.tracktrace).subscribe(
            () => {
                const message = Message.createSuccessMessage(
                    "Pakket is verwijderd",
                    "Het verwijderen van het pakket is gelukt!",
                );
                this.closeModal(message);
            },
            () => {
                const message = Message.createErrorMessage(
                    "Fout bij verwijderen",
                    "Er is iets misgegaan bij het verwijderen. Probeer het later nog eens.",
                );
                this.closeModal(message);
            },
        );
    }
}
