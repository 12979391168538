<app-button-modal title="Synchroniseer DHL labels" allowExit="true" class="sync-labels-modal">
    <div *ngIf="uploadStatus">
        <dl>
            <dd *ngIf="uploadStatus.isCompleted">Laatste import:</dd>
            <dd *ngIf="!uploadStatus.isCompleted">Huidige import:</dd>
            <dt>
                {{ uploadStatus.dateCreated | splitDate }} om
                {{ uploadStatus.dateCreated | splitTime }}
            </dt>
        </dl>
        <table *ngIf="uploadStatus" class="table">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Totaal</th>
                    <th scope="col">Succes</th>
                    <th scope="col">Fouten</th>
                    <th scope="col">Resterend</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tr>
                <td>{{ uploadStatus.totalAmount }}</td>
                <td>{{ uploadStatus.done }}</td>
                <td>{{ uploadStatus.erroredAmount }}</td>
                <td>
                    {{ uploadStatus.totalAmount - uploadStatus.done - uploadStatus.erroredAmount }}
                </td>
                <td>{{ uploadStatus.status }}</td>
                <td>
                    <div>
                        <button
                            *ngIf="!uploadStatus.isCompleted"
                            type="button"
                            class="btn btn-primary"
                            (click)="this.updateLatestUpdateStatus()"
                        >
                            Ververs
                        </button>
                    </div>
                </td>
            </tr>
        </table>
    </div>

    <div *ngIf="!uploadStatus || uploadStatus.isCompleted">
        <div *ngIf="!csvIsParsed; else showData">
            <p>
                Voor een voorbeeld csv, zie hier het
                <a href="https://my.dhlparcel.nl/business/#/v2/reporting/shipments">download csv</a>
                overzicht van DHL.
            </p>
            <p>Het CSV bestand kan door komma's of door puntkomma's gescheiden worden.</p>
            <p>
                <strong>Let op:</strong>
                De eerste regel van je CSV bestand wordt niet mee genomen in de upload!
            </p>
            <p>In deze rij staan aanwijzingen wat in welke kolom dient te staan.</p>
        </div>

        <ng-template #showData>
            <h5>Label(s) geïmporteerd: {{ labels.length }}</h5>
        </ng-template>

        <div *ngIf="validationErrors">
            <h3 class="error__title">Validatie fouten</h3>
            <div *ngFor="let error of validationErrors" class="error">
                <p class="error__detail">{{ error.fields[0] }}</p>
                <p class="error__detail">{{ error.reason }}</p>
            </div>
        </div>

        <form #packageForm="ngForm">
            <div class="buttons">
                <label (change)="changeListener($event)" class="btn btn-primary">
                    <span *ngIf="!fileName"
                        ><i class="fas fa-arrow-alt-from-bottom fa-lg"></i> KIES EEN BESTAND</span
                    >
                    <span *ngIf="fileName"
                        ><i class="fas fa-arrow-alt-from-bottom fa-lg"></i>{{ fileName }}</span
                    >
                    <input type="file" accept=".csv" value="" />
                </label>
                <button
                    *ngIf="csvIsParsed"
                    [disabled]="uploadStatus && !uploadStatus.isCompleted"
                    class="btn btn-primary"
                    type="submit"
                    (click)="buildInput()"
                >
                    Synchroniseer
                </button>
            </div>
        </form>
    </div>
</app-button-modal>
