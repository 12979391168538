import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { E_TRACKING_STATUSES } from "../../../helpers/constants";
import { E_EXTERNAL_PARTIES, SHIPPING_COMPANIES } from "../../../helpers/constants/transporters";
import { BackendService } from "../../../services/backend.service";

@Component({
    selector: "app-bulk-status-modal",
    templateUrl: "./bulk-status-modal.component.html",
    styleUrls: ["./bulk-status-modal.component.scss"],
})
export class BulkStatusModalComponent implements OnInit {
    SHIPPING_COMPANIES = SHIPPING_COMPANIES;
    total: number = 0;

    totalPerTransporter: { [key: number]: number } = {};

    webshopStats: any = {};

    constructor(private backend: BackendService, private router: Router) {}

    buttons: ButtonModal.Buttons = [
        {
            text: "Status updaten",
            onClick: () => this.updateBulkStatus(),
        },
    ];

    ngOnInit() {
        SHIPPING_COMPANIES.forEach((company) => {
            this.scannedByViabodeCall(company.value);
        });
    }

    get activeShippingCompanies() {
        return SHIPPING_COMPANIES.filter((company) => this.totalPerTransporter[company.value] > 0);
    }

    scannedByViabodeCall(transporter: E_EXTERNAL_PARTIES) {
        const status = E_TRACKING_STATUSES.PACKAGE_SCANNED_BY_VIABODE;

        this.backend
            .getPackageStats(`status=${status}&external_id=${transporter}`)
            .subscribe((result) => {
                this.webshopStats[E_EXTERNAL_PARTIES[transporter]] = result.data;
                this.totalPerTransporter[transporter] = result.total;
                this.total += result.total;
            });
    }

    updateBulkStatus() {
        const status = E_TRACKING_STATUSES.PACKAGE_SCANNED_BY_VIABODE;
        const newStatus = E_TRACKING_STATUSES.PACKAGE_GIVEN_TO_EXTERNAL;

        this.backend.bulkExternalUpdate(status, newStatus).subscribe((amount) => {
            if (amount || amount === 0) {
                this.backend.showMessage(true, `Er zijn ${amount} pakketten succesvol geüpdatet!`);
                this.router
                    .navigateByUrl("/RefrshComponent", { skipLocationChange: true })
                    .then(() => this.router.navigateByUrl("/dashboard(modal:bulk-status)"));
            } else {
                this.backend.showMessage(
                    false,
                    "er is iets foutgegaan met het updaten van pakketten!",
                );
            }
        });
    }
}
