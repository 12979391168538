import moment from "moment";
import { User } from "./user.model";
import { E_USER_TYPES } from "../helpers/constants";
import { ViawebshopUpdateJSON } from "./json/viawebshopUpdateJSON";
import { UserJSON } from "./json/userJSON";

export class ViaWebshop extends User {
    constructor(
        public override dateCreated: moment.Moment,
        public override postcode: string,
        public override houseNumber: string,
        public override houseNumberExtra: string,
        public override city: string,
        public override streetname: string,
        public override addressExtra: string,
        public override initials: string,
        public override firstname: string,
        public override middlename: string,
        public override lastname: string,
        public override email: string,
        public override companyName: string,
        public override country: string,
        public override phone: string,
        public override domain: string,
        public override userName: string,
        public override rights: string[],
        public override isActive: boolean,
        public override accountType: number,
        public override webshopConnectorType: string,
        public override channelId: string,
        public override parentShopId: number,
        public override kvk: string,
        public override btw: string,
        public override iban: string,
        public override bic: string,
        public subAccounts: { id: number; name: string }[],
        id?: number,
    ) {
        super(
            dateCreated,
            postcode,
            houseNumber,
            houseNumberExtra,
            city,
            streetname,
            addressExtra,
            initials,
            firstname,
            middlename,
            lastname,
            email,
            companyName,
            country,
            E_USER_TYPES.VIAWEBSHOP,
            phone,
            rights,
            userName,
            isActive,
            E_USER_TYPES.VIAWEBSHOP,
            id,
        );
    }

    static override fromJSON(json: UserJSON) {
        let { account } = json;
        if (!account) {
            account = {
                user_name: undefined,
                active: false,
                type: undefined,
            };
        }
        let rights = [];
        if (json.rights) {
            rights = Object.entries(json.rights)
                .filter(([, value]) => value == "true")
                .map(([key]) => key);
        }
        return new ViaWebshop(
            moment(json.date_created),
            json.postcode,
            json.housenr,
            json.housenr_extra,
            json.city,
            json.streetname,
            json.address_extra,
            json.initials,
            json.firstname,
            json.middlename,
            json.lastname,
            json.email,
            json.company_name,
            json.country,
            json.phone,
            json.viawebshop.domain,
            account.user_name,
            rights,
            account.active,
            account.type,
            json.viawebshop.webshop_connector_type,
            json.viawebshop.webshop_connector_config
                ? JSON.parse(json.viawebshop.webshop_connector_config).channel
                : null,
            json.viawebshop.parent_shop_id,
            json.viawebshop.kvk,
            json.viawebshop.btw,
            json.viawebshop.iban,
            json.viawebshop.bic,
            json.viawebshop.sub_accounts,
            json.id,
        );
    }

    static override createEmpty() {
        return new ViaWebshop(
            moment(),
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "NL",
            "",
            "",
            "",
            [],
            true,
            E_USER_TYPES.VIAWEBSHOP,
            "",
            "",
            null,
            "",
            "",
            "",
            "",
            [],
        );
    }

    clone() {
        return new ViaWebshop(
            this.dateCreated,
            this.postcode,
            this.houseNumber,
            this.houseNumberExtra,
            this.city,
            this.streetname,
            this.addressExtra,
            this.initials,
            this.firstname,
            this.middlename,
            this.lastname,
            this.email,
            this.companyName,
            this.country,
            this.phone,
            this.domain,
            this.userName,
            this.rights,
            this.isActive,
            this.accountType,
            this.webshopConnectorType,
            this.channelId,
            this.parentShopId,
            this.kvk,
            this.btw,
            this.iban,
            this.bic,
            this.subAccounts,
            this.id,
        );
    }

    toViawebshopUpdateJSON(): ViawebshopUpdateJSON {
        return {
            user: {
                postcode: this.postcode,
                housenr: this.houseNumber,
                housenr_extra: this.houseNumberExtra,
                city: this.city,
                streetname: this.streetname,
                address_extra: this.addressExtra,
                phone: this.phone,
                firstname: this.firstname,
                middlename: this.middlename,
                lastname: this.lastname,
                email: this.email,
                company_name: this.companyName,
                country: this.country,
                type: E_USER_TYPES.VIAWEBSHOP,
            },
            viawebshop: {
                webshop_connector_type: this.webshopConnectorType,
                webshop_connector_config: this.channelId
                    ? `{"channel": "${this.channelId}"}`
                    : null,
                kvk: this.kvk,
                btw: this.btw,
                domain: this.domain,
                iban: this.iban,
                bic: this.bic,
                parent_shop_id: this.parentShopId,
            },
        };
    }

    toCreateJSON() {
        const data = {
            user: {
                postcode: this.postcode,
                housenr: this.houseNumber,
                housenr_extra: this.houseNumberExtra,
                address_extra: this.addressExtra,
                phone: this.phone,
                type: E_USER_TYPES.VIAWEBSHOP,
                firstname: this.firstname,
                middlename: this.middlename,
                lastname: this.lastname,
                email: this.email,
                company_name: this.companyName,
            },
            viawebshop: {
                webshop_connector_type: this.webshopConnectorType,
                webshop_connector_config: this.channelId
                    ? `{"channel": "${this.channelId}"}`
                    : null,
                domain: this.domain,
                kvk: this.kvk,
                btw: this.btw,
                iban: this.iban,
                bic: this.bic,
                parent_shop_id: this.parentShopId,
            },
            account: {
                username: this.email,
            },
        };
        return data;
    }

    get displayName() {
        return this.companyName || this.domain || "webshop-" + this.id;
    }
}
