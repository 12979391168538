<form [formGroup]="viaWebshopForm" (ngSubmit)="onSubmit()" class="webshop-edit container">
    <h2 class="webshop-edit__title">Algemeen</h2>
    <div class="form-group row">
        <div
            [appInlineError]="{ errors: errors }"
            fieldName="user.postcode,user.housenr,user.housenr_extra,user.country"
        ></div>
    </div>

    <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right" for="companyName"
            >Bedrijfsnaam<i class="fas fa-asterisk"></i
        ></label>
        <div class="col-sm-10">
            <input
                type="text"
                [ngClass]="fieldClass('companyName')"
                class="form-control"
                placeholder="ViaTim"
                formControlName="companyName"
                [appInlineError]="{ errors: errors, prefix: 'user' }"
            />
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right" for="name"
            >Naam<i class="fas fa-asterisk"></i
        ></label>
        <div class="col">
            <input
                type="text"
                [ngClass]="fieldClass('firstname')"
                class="form-control"
                placeholder="Voornaam"
                formControlName="firstname"
                [appInlineError]="{ errors: errors, prefix: 'user' }"
            />
        </div>
        <div class="col">
            <input
                type="text"
                [ngClass]="fieldClass('middlename')"
                class="form-control"
                placeholder="Tussenvoegsel"
                formControlName="middlename"
                [appInlineError]="{ errors: errors, prefix: 'user' }"
            />
        </div>
        <div class="col">
            <input
                type="text"
                [ngClass]="fieldClass('lastname')"
                class="form-control"
                placeholder="Achternaam"
                formControlName="lastname"
                [appInlineError]="{ errors: errors, prefix: 'user' }"
            />
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right" for="streetname"
            >Adres<i class="fas fa-asterisk"></i
        ></label>
        <div class="col">
            <input
                type="text"
                [ngClass]="fieldClass('streetname')"
                class="form-control"
                placeholder="Straatnaam"
                formControlName="streetname"
                [appInlineError]="{ errors: errors, prefix: 'user' }"
            />
        </div>
        <div class="col">
            <input
                type="text"
                [ngClass]="fieldClass('housenr')"
                class="form-control"
                placeholder="Huisnummer"
                formControlName="housenr"
                [appInlineError]="{ errors: errors, prefix: 'user' }"
            />
        </div>
        <div class="col">
            <input
                type="text"
                class="form-control"
                placeholder="Toevoeging"
                formControlName="housenr_extra"
                [appInlineError]="{ errors: errors, prefix: 'user' }"
            />
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right text-wrap" for="postcode"
            >Postcode en plaats<i class="fas fa-asterisk"></i
        ></label>
        <div class="col">
            <input
                type="text"
                [ngClass]="fieldClass('postcode')"
                class="form-control"
                placeholder="Postcode"
                formControlName="postcode"
                [appInlineError]="{ errors: errors, prefix: 'user' }"
            />
        </div>
        <div class="col">
            <input
                type="text"
                [ngClass]="fieldClass('city')"
                class="form-control"
                placeholder="Plaatsnaam"
                formControlName="city"
                [appInlineError]="{ errors: errors, prefix: 'user' }"
            />
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right" for="phone"
            >Telefoon<i class="fas fa-asterisk"></i
        ></label>
        <div class="col">
            <input
                [ngClass]="fieldClass('phone')"
                type="phone"
                class="form-control"
                placeholder="0612345678"
                formControlName="phone"
                [appInlineError]="{ errors: errors, prefix: 'user' }"
            />
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right" for="email"
            >Email<i class="fas fa-asterisk"></i
        ></label>
        <div class="col-sm-10">
            <input
                [ngClass]="fieldClass('email')"
                type="email"
                class="form-control"
                placeholder="voorbeeld@viatim.nl"
                formControlName="email"
                [appInlineError]="{ errors: errors, prefix: 'user' }"
            />
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right" for="domain"
            >Domein<i class="fas fa-asterisk"></i
        ></label>
        <div class="col-sm-10">
            <input
                [ngClass]="fieldClass('domain')"
                type="domain"
                class="form-control"
                placeholder="https://www.viatim.nl/"
                formControlName="domain"
                [appInlineError]="{ errors: errors, prefix: 'viawebshop' }"
            />
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right" for="kvk"
            >KVK nummer<i class="fas fa-asterisk"></i
        ></label>
        <div class="col-sm-10">
            <input
                type="text"
                class="form-control"
                placeholder="12345678"
                formControlName="kvk"
                [appInlineError]="{ errors: errors, prefix: 'viawebshop' }"
            />
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right" for="btw"
            >BTW nummer<i class="fas fa-asterisk"></i
        ></label>
        <div class="col-sm-10">
            <input
                type="text"
                class="form-control"
                placeholder="12345678901234"
                formControlName="btw"
                [appInlineError]="{ errors: errors, prefix: 'viawebshop' }"
            />
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right" for="iban">IBAN + BIC</label>
        <div class="col">
            <input
                type="text"
                [ngClass]="fieldClass('iban')"
                class="form-control"
                placeholder="NL00AAAA12345678"
                formControlName="iban"
                [appInlineError]="{ errors: errors, prefix: 'viawebshop' }"
            />
        </div>
        <div class="col">
            <div class="input-group">
                <input
                    type="text"
                    [ngClass]="fieldClass('bic')"
                    class="form-control"
                    placeholder="ABNANL2A"
                    formControlName="bic"
                    [appInlineError]="{ errors: errors, prefix: 'viawebshop' }"
                />
                <button
                    (click)="lookupBIC()"
                    type="button"
                    class="btn btn-outline-secondary bic-button form-control"
                >
                    <i class="fad fa-magic"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right" for="webshopConnectorType"
            >Webshopkoppeling</label
        >
        <div class="col-sm-5">
            <select
                [ngClass]="fieldClass('webshopConnectorType')"
                class="form-control"
                formControlName="webshopConnectorType"
            >
                <option *ngFor="let type of webshopConnectorTypes" [value]="type.value">
                    {{ type.label }}
                </option>
            </select>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right" for="channelId">Channel Id</label>
        <div class="col">
            <input
                type="text"
                [ngClass]="fieldClass('channelId')"
                class="form-control"
                placeholder="CHANNEL_NAME"
                formControlName="channelId"
                fieldName="viawebshop.webshop_connector_config"
                [appInlineError]="{ errors: errors }"
            />
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right" for="parentShopId"
            >Is onderdeel van webshop</label
        >
        <div class="col">
            <input
                type="number"
                [ngClass]="fieldClass('parentShopId')"
                class="form-control"
                placeholder="13445"
                formControlName="parentShopId"
                [appInlineError]="{ errors: errors, prefix: 'parentShopId' }"
            />
        </div>
    </div>

    <div class="row justify-content-md-center p-4">
        <button
            class="col btn btn-primary"
            [disabled]="!viaWebshopForm.valid"
            [ngClass]="viaWebshopForm.valid ? '' : 'inverse'"
        >
            Opslaan
        </button>
        <a (click)="cancel()" href="javascript:;" class="btn btn-secondary offset-sm-1 col"
            >Annuleren</a
        >
    </div>
</form>
