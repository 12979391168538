<nav class="sidebar" [ngClass]="{ 'sidebar--collapsed': collapsed }">
    <div class="navs">
        <ul class="nav nav-logo">
            <li class="maxHeight">
                <a class="navLink maxHeight">
                    <span class="sidebar__icon">
                        <app-viatim-icon></app-viatim-icon>
                        <span
                            *ngIf="!collapsed"
                            class="nav-logo__greeting"
                            [@fontSizeAnimation]
                            [@.disabled]="disableAnimation"
                        >
                            Welkom<span *ngIf="currentUser$ | async as currentUser" class="name">
                                {{ currentUser.getFullName() }}</span
                            >!
                        </span>
                    </span>
                    <span *ngIf="currentUser$ | async as currentUser" class="label">
                        Welkom {{ currentUser.getFullName() }}, super bedankt dat je klant bent!
                    </span>
                </a>
            </li>
        </ul>

        <ul class="nav primary">
            <li *ngIf="showDashboard">
                <a class="navLink" routerLink="/dashboard" routerLinkActive="active">
                    <span class="sidebar__icon">
                        <i class="fad fa-home fa-lg iconFA"></i>
                    </span>
                    <span class="label">Dashboard</span>
                </a>
            </li>

            <li>
                <a class="navLink" routerLink="/packages" routerLinkActive="active">
                    <span class="sidebar__icon">
                        <i class="fad fa-box fa-lg iconFA"></i>
                    </span>
                    <span class="label">Pakketten</span>
                </a>
            </li>

            <li *ngIf="showWebshops">
                <a class="navLink" routerLink="/webshops" routerLinkActive="active">
                    <span class="sidebar__icon">
                        <i class="fad fa-shopping-cart fa-lg iconFA"></i>
                    </span>
                    <span class="label">Webshops</span>
                </a>
            </li>

            <li *ngIf="showContacts">
                <a class="navLink" routerLink="/contacts" routerLinkActive="active">
                    <span class="sidebar__icon">
                        <i class="fad fa-address-book fa-lg iconFA"></i>
                    </span>
                    <span class="label">Adresboek</span>
                </a>
            </li>
        </ul>

        <ul class="nav secondary">
            <li>
                <a class="navLink" routerLink="/account-management" routerLinkActive="active">
                    <span class="sidebar__icon">
                        <i class="fad fa-user-circle fa-lg iconFA"></i>
                    </span>
                    <span class="label">Je account</span>
                </a>
            </li>

            <li>
                <a class="navLink" routerLink="/login" (click)="logout()">
                    <span class="sidebar__icon">
                        <i class="fad fa-solid fa-left-from-bracket iconFA"></i>
                    </span>
                    <span class="label">Uitloggen</span>
                </a>
            </li>
        </ul>
    </div>

    <ul class="nav collapse">
        <li>
            <a class="navLink" (click)="toggleSideBar()">
                <span class="sidebar__icon">
                    <i class="fad fa-solid fa-arrow-left-to-line iconFA"></i>
                </span>
                <span class="label">{{ collapsed ? "Uitklappen" : "Inklappen" }}</span>
            </a>
        </li>
    </ul>
</nav>
