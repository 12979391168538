import { Injectable } from "@angular/core";
import * as i18nIsoCountries from "i18n-iso-countries";
import { NO_CUSTOM_DOCUMENT_COUNTRY_CODES } from "../helpers/constants";
import { E_EXTERNAL_PARTIES } from "../helpers/constants/transporters";
import { Country } from "../models/country";

const CAN_BE_SEND_FROM = {};

CAN_BE_SEND_FROM[E_EXTERNAL_PARTIES.BPOST] = ["BE"];

CAN_BE_SEND_FROM[E_EXTERNAL_PARTIES.VIATIM] = ["NL", "BE", "LU", "DE", "FR"];
CAN_BE_SEND_FROM[E_EXTERNAL_PARTIES.MYPARCEL] = ["NL", "BE", "LU", "DE", "FR"];

CAN_BE_SEND_FROM[E_EXTERNAL_PARTIES.PARCELPRO] = ["NL", "BE", "LU", "DE", "FR"];
CAN_BE_SEND_FROM[E_EXTERNAL_PARTIES.POSTNL] = ["NL", "BE", "LU", "DE", "FR"];
CAN_BE_SEND_FROM[E_EXTERNAL_PARTIES.RJP] = ["NL"];
CAN_BE_SEND_FROM[E_EXTERNAL_PARTIES.CYCLOON] = ["NL"];
CAN_BE_SEND_FROM[E_EXTERNAL_PARTIES.DPD] = ["NL", "DE"];
CAN_BE_SEND_FROM[E_EXTERNAL_PARTIES.BUDBEE] = ["NL"];
CAN_BE_SEND_FROM[E_EXTERNAL_PARTIES.DHL_4Y1] = ["NL", "BE", "LU"];
CAN_BE_SEND_FROM[E_EXTERNAL_PARTIES.DHL_4Y2] = ["NL", "BE", "LU"];
CAN_BE_SEND_FROM[E_EXTERNAL_PARTIES.DHL_DE] = ["NL", "DE"];
CAN_BE_SEND_FROM[E_EXTERNAL_PARTIES.COLIS_PRIVE] = ["NL", "FR"];
CAN_BE_SEND_FROM[E_EXTERNAL_PARTIES.GLS] = ["NL"];

const CAN_BE_SEND_TO = {};
CAN_BE_SEND_TO[E_EXTERNAL_PARTIES.RJP] = ["NL"];
CAN_BE_SEND_TO[E_EXTERNAL_PARTIES.CYCLOON] = ["NL"];
CAN_BE_SEND_TO[E_EXTERNAL_PARTIES.PARCELPRO] = NO_CUSTOM_DOCUMENT_COUNTRY_CODES;
CAN_BE_SEND_TO[E_EXTERNAL_PARTIES.MYPARCEL] = NO_CUSTOM_DOCUMENT_COUNTRY_CODES;
CAN_BE_SEND_TO[E_EXTERNAL_PARTIES.POSTNL] = NO_CUSTOM_DOCUMENT_COUNTRY_CODES;
CAN_BE_SEND_TO[E_EXTERNAL_PARTIES.VIATIM] = NO_CUSTOM_DOCUMENT_COUNTRY_CODES;
CAN_BE_SEND_TO[E_EXTERNAL_PARTIES.DPD] = ["NL", "DE"];
CAN_BE_SEND_TO[E_EXTERNAL_PARTIES.BUDBEE] = ["NL"];
CAN_BE_SEND_TO[E_EXTERNAL_PARTIES.DHL_4Y1] = NO_CUSTOM_DOCUMENT_COUNTRY_CODES;
CAN_BE_SEND_TO[E_EXTERNAL_PARTIES.DHL_4Y2] = NO_CUSTOM_DOCUMENT_COUNTRY_CODES;
CAN_BE_SEND_TO[E_EXTERNAL_PARTIES.COLIS_PRIVE] = ["NL", "FR", "LU"];
CAN_BE_SEND_TO[E_EXTERNAL_PARTIES.DHL_DE] = ["NL", "DE"];
CAN_BE_SEND_TO[E_EXTERNAL_PARTIES.GLS] = NO_CUSTOM_DOCUMENT_COUNTRY_CODES;

CAN_BE_SEND_TO[E_EXTERNAL_PARTIES.BPOST] = ["BE"];

CAN_BE_SEND_TO[E_EXTERNAL_PARTIES.DHL_ECOM] = ["NL"];

CAN_BE_SEND_FROM[E_EXTERNAL_PARTIES.DHL_ECOM] = ["NL"];

@Injectable()
export class CountryService {
    public countries: Country[];

    constructor() {
        // eslint-disable-next-line global-require
        i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/nl.json"));
        const countryMap = i18nIsoCountries.getNames("nl");
        this.countries = Object.keys(countryMap).map((key) => new Country(key, countryMap[key]));
        this.countries.sort((c1, c2) => c1.name.localeCompare(c2.name));
    }

    countriesThatExternalCanSendTo(externalId: number) {
        return this.countries.filter((country) =>
            CAN_BE_SEND_TO[externalId].includes(country.code),
        );
    }

    countriesThatExternalCanSendFrom(externalId: number) {
        return this.countries.filter((country) =>
            CAN_BE_SEND_FROM[externalId].includes(country.code),
        );
    }

    findCountryByISO(code: string): Country {
        return this.countries.find((country) => code === country.code);
    }
}
