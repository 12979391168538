import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ContactService } from "../services/contact.service";
import { BackendService } from "../services/backend.service";
import { User } from "../models/user.model";

const PAGE_SIZE = 20;

@Component({
    selector: "app-contacts",
    templateUrl: "./contacts.component.html",
    styleUrls: ["./contacts.component.scss"],
})
export class ContactsComponent implements OnInit {
    displayedContacts: User[];

    contacts: User[];

    details: User;

    page = 0;

    searchTerm: "";

    contactForDelete: User = undefined;

    constructor(
        private router: Router,
        private contactService: ContactService,
        private backend: BackendService,
    ) {}

    buttons: ButtonModal.Buttons = [
        {
            text: "Verwijder",
            onClick: () => this.delete(),
        },
    ];

    ngOnInit() {
        this.loadContacts();
    }

    loadContacts() {
        this.contactService.getContacts().subscribe(
            (result) => {
                this.contacts = result.contacts;
                this.onChangeSearchTerm();
            },
            () => {
                this.backend.showMessage(
                    false,
                    "Er heeft zich een fout voorgedaan bij het inladen van de contacten.",
                );
            },
        );
    }

    onChangeSearchTerm() {
        const start = this.page * PAGE_SIZE;
        const end = (this.page + 1) * PAGE_SIZE - 1;
        let result = this.contacts;
        const lowerSearchTerm = this.searchTerm?.toLowerCase();
        if (this.searchTerm?.trim().length > 1) {
            result = this.contacts.filter((c) => c.getSearchTerm().indexOf(lowerSearchTerm) >= 0);
        }
        this.displayedContacts = result.slice(start, end);
    }

    closeDialog() {
        this.contactForDelete = undefined;
    }

    deleteContact(contact: User) {
        this.contactForDelete = contact;
    }

    delete() {
        this.backend.deleteContact(this.contactForDelete.id).subscribe(
            () => {
                this.backend.showMessage(true, "Het verwijderen van een contact is gelukt!");
                this.contactForDelete = undefined;
                this.loadContacts();
            },
            () => {
                this.backend.showMessage(
                    false,
                    "Foutcode 13a: Het verwijderen van een contact is niet gelukt.",
                );
            },
        );
    }

    createContact() {
        this.router.navigateByUrl("/contacts(modal:create-contact)");
    }

    showContactDetails(contact: User) {
        this.details = contact;
    }
}
