<div (click)="closeModal('background')" class="background">
    <div (click)="$event.stopPropagation()" class="modal">
        <div class="header">
            <div class="headerText">
                <h1>{{ title }}</h1>
                <div class="subtitleContainer">
                    <h2 *ngIf="subtitle">{{ subtitle }}</h2>
                </div>
            </div>
            <button
                *ngIf="allowExit"
                (click)="closeModal('closeButton')"
                class="closeButton --modern --main-button"
                title="Modal sluiten"
            >
                <i class="fa fa-solid fa-xmark"></i>
            </button>
        </div>

        <div class="content">
            <ng-content></ng-content>
        </div>

        <div *ngIf="showButtons" class="buttons">
            <button
                *ngFor="let button of buttons"
                [disabled]="disableButtons"
                (click)="button.onClick(closeModal)"
                class="--modern --main-button"
            >
                {{ button.text }}
            </button>
        </div>
    </div>
</div>
