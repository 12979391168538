<app-button-modal title="Bulk Status Update" [buttons]="buttons" allowExit="true">
    <form>
        <div class="total">
            <p>Totaal aantal pakketten: {{ total }}</p>
        </div>

        <div class="title-container">
            <table>
                <tr *ngFor="let company of activeShippingCompanies">
                    <td>{{ company.name }}</td>
                    <td>{{ totalPerTransporter[company.value] }}</td>
                </tr>
            </table>
        </div>

        <div class="container">
            <table class="stat-container" *ngFor="let company of activeShippingCompanies">
                <tr *ngFor="let webshop of webshopStats[company.value]">
                    <td>{{ webshop.firstname }}</td>
                    <td>{{ webshop.count }}</td>
                </tr>
            </table>
        </div>
    </form>
</app-button-modal>
