<app-button-modal title="Facturatie Export" [buttons]="buttons" allowExit="true">
    <form>
        <div class="form-group">
            <label for="exportYear">Jaar</label>
            <select [(ngModel)]="exportYear" name="exportYear">
                <option
                    *ngFor="let year of yearOptions"
                    [value]="year"
                    [selected]="year === exportYear"
                >
                    {{ year }}
                </option>
            </select>
        </div>

        <div class="form-group">
            <label for="exportYear">Periode</label>
            <div class="form-row">
                <div class="btn-group" role="group">
                    <button
                        type="button"
                        class="btn"
                        (click)="setPeriod('week')"
                        [ngClass]="{
                            'btn-primary': period === 'week',
                            'btn-outline-primary': period !== 'week'
                        }"
                    >
                        Week
                    </button>
                    <button
                        type="button"
                        class="btn"
                        (click)="setPeriod('month')"
                        [ngClass]="{
                            'btn-primary': period === 'month',
                            'btn-outline-primary': period !== 'month'
                        }"
                    >
                        Maand
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="period === 'month'" class="form-group">
            <label for="exportMonth">Maand</label>
            <select [(ngModel)]="exportMonth" name="exportMonth">
                <option
                    *ngFor="let month of monthOptions; index as i"
                    [value]="i"
                    [selected]="i === exportMonth"
                >
                    {{ month }}
                </option>
            </select>
        </div>

        <div *ngIf="period === 'week'" class="form-group">
            <label for="exportWeek">Week</label>
            <select *ngIf="period === 'week'" [(ngModel)]="exportWeek" name="exportWeek">
                <option
                    *ngFor="let week of weekOptions; index as i"
                    [value]="week"
                    [selected]="i === exportWeek"
                >
                    {{ week }}
                </option>
            </select>
        </div>
    </form>
</app-button-modal>
