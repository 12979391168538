<app-button-modal
    *ngIf="selectedPackages$ | async as selectedPackages"
    title="Bulk verwijderen"
    subtitle="Let op: Weet je zeker dat je deze {{
        selectedPackages.length
    }} pakketten wilt verwijderen?"
    allowExit="true"
    [buttons]="buttons"
>
    Pakketten die niet in concept staan zullen niet verwijderd worden.
</app-button-modal>
