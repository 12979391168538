import moment from "moment";

export const MONTH_NAMES_DUTCH = [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December",
];

export const currentThemeName = () => {
    const now = moment();
    const year = now.year();

    const xmasStart = moment(`${year}-12-06`);
    const xmasEnd = moment(`${year + 1}-01-01`);

    // Check if Christmas
    if (now.isAfter(xmasStart) && now.isBefore(xmasEnd)) {
        return "xmas";
    }

    // Check if Halloween

    // Else
    return "normal";
};

export const surroundingYearOptions = (before: number, after: number = 0) => {
    const currentYear = moment().get("year");
    const yearOptions = [];
    for (let index = -before; index <= after; index++) {
        yearOptions.push(currentYear + index);
    }
    return yearOptions;
};

export const stringToMoment = (value: string) => {
    if (value) {
        return moment(value);
    }
    return null;
};
