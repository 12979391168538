<app-button-modal
    title="Bulk print label"
    [subtitle]="subtitle"
    [buttons]="buttons"
    [hideButtons]="showLoading || failures.length !== 0"
    [allowExit]="!showLoading || failures.length !== 0"
>
    <div class="container">
        <div *ngIf="!showLoading && failures.length === 0" class="warning">
            <div>
                <p><b>Let op:</b></p>
                <ul>
                    <li>
                        Wanneer je deze labels print, worden alle geselecteerde pakketten uit
                        concept gehaald.
                    </li>
                    <li>
                        Indien je DHL labels gaat printen zullen de ontvangers van deze pakketten
                        een ViaTim mail krijgen met hun Track and Trace informatie!
                    </li>
                </ul>
            </div>
        </div>
        <div class="loading" *ngIf="showLoading">
            <div class="progress">
                <div
                    class="progress-bar bg-primary"
                    role="progressbar"
                    [attr.aria-valuenow]="counter"
                    aria-valuemin="0"
                    [attr.aria-valuemax]="total"
                    [style.width.%]="progressPercentage"
                >
                    {{ counter }}
                </div>
            </div>
        </div>

        <div class="errors" *ngIf="failures.length > 0">
            <p>Het printen van de volgende labels is mislukt:</p>
            <ul>
                <li *ngFor="let failure of failures">{{ failure }}</li>
            </ul>
        </div>
    </div>
</app-button-modal>
