/* eslint-disable no-magic-numbers */

export enum E_USER_TYPES {
    NON_INTEGRATED_USER = 0,
    VIABODE = 2,
    VIADC = 3,
    VIAGEBRUIKER = 4,
    VIAWEBSHOP = 5,
    EXTERNAL_WEBSHOP = 6,
    EXTERNAL_PARTY = 7,
    MODERATOR = 8,
    GLOBAL_ADMIN = 9,
    TRANSPORTER = 11,
    SUPPORT = 12,
}

export enum E_PACKAGE_TYPES {
    STANDARD_PACKAGE_L10KG = 0,
    STANDARD_PACKAGE_H10KG = 1,
    STANDARD_PACKAGE_BRIEF = 2,
    STANDARD_SAME_DAY_DELIVERY = 3,
    STANDARD_PACKAGE_H20KG = 4,
    STANDARD_PACKAGE_L15KG = 5,
    STANDARD_PACKAGE_H15KG = 6,
    STANDARD_PACKAGE_H30KG = 7,
    STANDARD_PACKAGE_WEBSHOP = 8,
    RETOUR_PACKAGE = 9,
    CONSUMER_PACKAGE = 10,
    STANDARD_PACKAGE_ENVELOPE = 11,
}

export enum CAPABILITIES {
    INSURANCE = "INSURANCE",
    ID_CHECK = "ID_CHECK",
    NO_NEIGHBOURS = "NO_NEIGHBOURS",
    PROOF_OF_DELIVERY = "PROOF_OF_DELIVERY",
    SAME_DAY = "SAME_DAY",
    PREMIUM = "PREMIUM",
    EVENING_DELIVERY = "EVENING_DELIVERY",
}

export enum E_TRACKING_STATUSES {
    PACKAGE_ADDED_TO_SYSTEM = 0,
    PACKAGE_SCANNED_BY_DC = 1,
    PACKAGE_SCANNED_BY_VIABODE = 2,
    PACKAGE_SCANNED_BY_VIAPUNT = 3,
    PACKAGE_GIVEN_TO_VIAGEBRUIKER = 4,
    PACKAGE_PICKED_UP_BY_VIAGEBRUIKER = 5,
    PACKAGE_VERIFIED_BY_VIAGEBRUIKER = 6,
    PACKAGE_GIVEN_TO_VIABODE = 7,
    PACKAGE_GIVEN_TO_VIAPUNT = 8,
    PACKAGE_GIVEN_TO_VIADC = 9,
    PACKAGE_GIVEN_TO_EXTERNAL = 10,
}

export enum E_PAPER_TYPES {
    STANDARD_PRINTER_A4_PAPER = 0,
    LABELPRINTER_A6_PAPER = 1,
}

export const DEFAULT_PAGE_SIZE = 30;
export const PAGE_SIZE_10 = 10;

export const PACKAGE_TYPES_TO_TEXT = [
    "Minder dan 10 kg",
    "Tussen 10 - 20 kg",
    "Brievenbuspakket",
    "Same-day Delivery pakket",
    "Meer dan 20 kg",
    "0 - 15kg",
    "15 - 30kg",
    "> 30kg",
    "Webshoppakket",
    "Retourpakket",
    "Consumentpakket",
    "Briefpost",
];

export const USER_TYPE_TO_TEXT = [
    "Klant",
    "ViaBode",
    "ViaDC",
    "ViaGebruiker",
    "Webshop",
    "Niet geïntegreerde webshop",
    "Externe partij",
    "Moderator",
    "Admin",
];

export const STATUS_TO_TEXT = [
    "Pakket toegevoegd aan systeem",
    "Pakket binnen op ViaDC",
    "Pakket in bezit van ViaBode",
    "Pakket in bezit van ViaPunt",
    "Pakket afgegeven aan Klant",
    "Pakket opgehaald door Klant",
    "Pakket geverifieerd door Klant",
    "Pakket afgegeven aan ViaBode",
    "Pakket afgegeven aan ViaPunt",
    "Pakket afgegeven aan viaDC",
    "Pakket afgegeven aan externe bode",
];

export const PAPER_TYPES_TO_TEXT = ["Standaard printer (A4)", "Labelprinter (A6)"];

export const BIC_CODES = {
    ABNA: "ABNANL2A",
    ADYB: "ADYBNL2A",
    AEGO: "AEGONL2U",
    ANDL: "ANDLNL2A",
    ARBN: "ARBNNL22",
    ARSN: "ARSNNL21",
    ASNB: "ASNBNL21",
    ATBA: "ATBANL2A",
    BCDM: "BCDMNL22",
    BCIT: "BCITNL2A",
    BICK: "BICKNL2A",
    BINK: "BINKNL21",
    BKCH: "BKCHNL2R",
    BKMG: "BKMGNL2A",
    BLGW: "BLGWNL21",
    BMEU: "BMEUNL21",
    BNDA: "BNDANL2A",
    BNGH: "BNGHNL2G",
    BNPA: "BNPANL2A",
    BOFA: "BOFANLNX",
    BOFS: "BOFSNL21002",
    BOTK: "BOTKNL2X",
    BUNQ: "BUNQNL2A",
    CHAS: "CHASNL2X",
    CITC: "CITCNL2A",
    CITI: "CITINL2X",
    COBA: "COBANL2X",
    DEUT: "DEUTNL2A",
    DHBN: "DHBNNL2R",
    DLBK: "DLBKNL2A",
    DNIB: "DNIBNL2G",
    EBUR: "EBURNL21",
    FBHL: "FBHLNL2A",
    FLOR: "FLORNL2A",
    FRGH: "FRGHNL21",
    FRNX: "FRNXNL2A",
    FTSB: "ABNANL2A",
    FVLB: "FVLBNL22",
    GILL: "GILLNL2A",
    HAND: "HANDNL2A",
    HHBA: "HHBANL22",
    HSBC: "HSBCNL2A",
    ICBK: "ICBKNL2A",
    INGB: "INGBNL2A",
    ISAE: "ISAENL2A",
    ISBK: "ISBKNL2A",
    KABA: "KABANL2A",
    KASA: "KASANL2A",
    KNAB: "KNABNL2H",
    KOEX: "KOEXNL2A",
    KRED: "KREDNL2X",
    LOCY: "LOCYNL2A",
    LOYD: "LOYDNL2A",
    LPLN: "LPLNNL2F",
    MHCB: "MHCBNL2A",
    MOYO: "MOYONL21",
    NNBA: "NNBANL2G",
    NWAB: "NWABNL2G",
    PCBC: "PCBCNL2A",
    RABO: "RABONL2U",
    RBRB: "RBRBNL21",
    SNSB: "SNSBNL2A",
    SOGE: "SOGENL2A",
    TEBU: "TEBUNL2A",
    TRIO: "TRIONL2U",
    UBSW: "UBSWNL2A",
    UGBI: "UGBINL2A",
    VOWA: "VOWANL21",
    ZWLB: "ZWLBNL21",
};

export interface ReasonedValidationError {
    fields: string[];
    reason: string;
}

export const ERROR_TRANSLATION = {
    invalid: {
        default: "De waarde is ongeldig",
        postcode: "De postcode is ongeldig",
        email: "Het emailadres is ongeldig",
        phone: "Het telefoonnummer is ongeldig",
        housenr: "Het huisnummer is ongeldig",
        webshop_connector_config: "De configuratie is ongeldig",
        iban: "Het IBAN is ongeldig",
        bic: "De BIC is ongeldig",
        ascription: "De toeschrijving is ongeldig",
        domain: "Het domein is ongeldig",
    },
    required: {
        default: "Deze waarde is verplicht",
        email: "Het emailadres is verplicht",
    },
    incorrect_type: {
        default: "De waarde heeft een verkeerd format",
        housenr: "Het huisnummer moet een nummer zijn",
        kvk: "Het KVK nummer moet een nummer zijn",
        exactid: "Het Exact relatie nummer moet een nummer zijn",
    },
    too_short: {
        default: "De waarde is te kort",
        postcode: "De postcode is te kort",
        phone: "Het telefoonnummer is te kort",
        iban: "Het IBAN is te kort",
        bic: "De BIC is te kort",
        btw: "Het BTW nummer is te kort",
        kvk: "Het KVK nummer is te kort",
        exactid: "Het Exact relatie nummer is te kort",
        ascription: "De toeschrijving is te kort",
    },
    too_long: {
        default: "De waarde is te lang",
        postcode: "De postcode is te lang",
        phone: "Het telefoonnummer is te lang",
        iban: "Het IBAN is te lang",
        bic: "De BIC is te lang",
        kvk: "Het KVK nummer is te lang",
        exactid: "Het Exact relatie nummer is te lang",
        ascription: "De toeschrijving is te lang",
        domain: "Het domein is te lang",
    },
    not_allowed: {
        default: "U heeft iets gedaan waar u geen rechten voor heeft",
        external: "U heeft geen rechten om deze vervoerder te gebruiken",
    },
    illegal_combination: {
        default: "De combinatie tussen velden bestaat niet",
        postcode: "De combinatie van postcode en huisnummer bestaat niet",
    },
    default: "De waarde is ongeldig",
};

export enum PARCEL_TYPE {
    MAILBOX = "MAILBOX",
    NORMAL = "NORMAL",
    HEAVY = "HEAVY",
}

export const FULL_PERCENTAGES = 100;

export const NO_CUSTOM_DOCUMENT_COUNTRY_CODES = [
    "BE",
    "BG",
    "CY",
    "DK",
    "DE",
    "EE",
    "ES",
    "FI",
    "FR",
    "GR",
    "HU",
    "IE",
    "IS",
    "IT",
    "HR",
    "LV",
    "LI",
    "LT",
    "LU",
    "NL",
    "AT",
    "PL",
    "PT",
    "RO",
    "SI",
    "SK",
    "CZ",
    "SE",
];
