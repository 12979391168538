import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { Store } from "@ngrx/store";
import { ViaWebshop } from "../../../models/viawebshop.model";
import { getBic } from "../../../helpers/bicHelpers";
import { Message } from "../../../models/message";
import { addMessage } from "../../../store/actions/message.actions";
import { ValidationError } from "../../../services/errors";
import { E_USER_TYPES } from "src/app/helpers/constants";

@Component({
    selector: "app-edit-webshop-form",
    templateUrl: "./edit-webshop-form.component.html",
    styleUrls: ["./edit-webshop-form.component.scss"],
})
export class EditWebshopFormComponent {
    viaWebshopForm = this.fb.group({
        companyName: ["", Validators.required],
        firstname: ["", Validators.required],
        middlename: [""],
        lastname: ["", Validators.required],
        streetname: ["", Validators.required],
        housenr: ["", Validators.required],
        housenr_extra: [""],
        addressExtra: [""],
        postcode: ["", Validators.required],
        city: ["", Validators.required],
        phone: ["", Validators.required],
        email: ["", Validators.required],
        domain: ["", Validators.required],
        kvk: ["", Validators.required],
        btw: ["", Validators.required],
        iban: [""],
        bic: [""],
        webshopConnectorType: [""],
        channelId: [""],
        parentShopId: [null as unknown as number],
    });

    webshopConnectorTypes = [
        { label: "", value: null },
        { label: "Deliverymatch", value: "deliverymatch" },
    ];

    private oldWebshop: ViaWebshop;

    @Input() errors: ValidationError[] = [];

    @Input()
    set viaWebshop(viaWebshop: ViaWebshop) {
        this.oldWebshop = viaWebshop;
        this.cleanForm();
        this.viaWebshopForm.setValue({
            firstname: viaWebshop.firstname,
            middlename: viaWebshop.middlename,
            lastname: viaWebshop.lastname,
            streetname: viaWebshop.streetname,
            housenr: viaWebshop.houseNumber,
            housenr_extra: viaWebshop.houseNumberExtra,
            addressExtra: viaWebshop.addressExtra,
            postcode: viaWebshop.postcode,
            city: viaWebshop.city,
            phone: viaWebshop.phone,
            email: viaWebshop.email,
            companyName: viaWebshop.companyName,
            kvk: viaWebshop.kvk,
            domain: viaWebshop.domain,
            webshopConnectorType: viaWebshop.webshopConnectorType,
            btw: viaWebshop.btw,
            iban: viaWebshop.iban,
            bic: viaWebshop.bic,
            channelId: viaWebshop.channelId,
            parentShopId: viaWebshop.parentShopId,
        });
    }

    @Output() onSave: EventEmitter<ViaWebshop> = new EventEmitter();

    @Output() onCancel: EventEmitter<void> = new EventEmitter();

    constructor(private fb: FormBuilder, private store: Store) {}

    fieldClass(fieldName: string, form = this.viaWebshopForm) {
        const field = form.get(fieldName);
        if (field.invalid && (field.dirty || field.touched)) {
            return "is-invalid";
        }
        return "";
    }

    dirtyForm() {
        Object.keys(this.viaWebshopForm.controls).forEach((key) => {
            this.viaWebshopForm.get(key).markAsDirty();
        });
    }

    cleanForm() {
        Object.keys(this.viaWebshopForm.controls).forEach((key) => {
            const field = this.viaWebshopForm.get(key);
            field.markAsPristine();
            field.markAsUntouched();
        });
        this.viaWebshopForm.markAsUntouched();
    }

    onSubmit() {
        if (!this.viaWebshopForm.valid) {
            this.dirtyForm();
            return;
        }
        const newViaWebshop = this.oldWebshop.clone();
        const form = this.viaWebshopForm.value;

        newViaWebshop.postcode = form.postcode;
        newViaWebshop.houseNumber = form.housenr;
        newViaWebshop.houseNumberExtra = form.housenr_extra;
        newViaWebshop.city = form.city;
        newViaWebshop.streetname = form.streetname;
        newViaWebshop.addressExtra = form.addressExtra;
        newViaWebshop.firstname = form.firstname;
        newViaWebshop.middlename = form.middlename;
        newViaWebshop.lastname = form.lastname;
        newViaWebshop.email = form.email;
        newViaWebshop.companyName = form.companyName;
        newViaWebshop.country = "NL";
        newViaWebshop.phone = form.phone;
        newViaWebshop.domain = form.domain;
        newViaWebshop.accountType = E_USER_TYPES.VIAWEBSHOP;
        newViaWebshop.webshopConnectorType = form.webshopConnectorType;
        newViaWebshop.channelId = form.channelId;
        newViaWebshop.kvk = form.kvk;
        newViaWebshop.btw = form.btw;
        newViaWebshop.iban = form.iban;
        newViaWebshop.bic = form.bic;
        newViaWebshop.parentShopId = form.parentShopId;

        this.onSave.emit(newViaWebshop);
    }

    cancel() {
        this.onCancel.emit();
    }

    lookupBIC() {
        const iban = this.viaWebshopForm.get("iban").value;
        if (iban) {
            const bic = getBic(iban);
            if (bic) {
                this.viaWebshopForm.get("bic").setValue(bic);
            } else {
                const message = Message.createErrorMessage(
                    "BIC niet gevonden",
                    `Er is geen BIC code gevonden voor iban ${iban}`,
                );
                this.store.dispatch(addMessage({ message }));
            }
        }
    }
}
