import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { ControlPanelPageComponent } from "./control-panel-page/control-panel-page.component";
import { ControlPanelModalComponent } from "./control-panel-modal/control-panel-modal.component";
import { ControlPanelWarningComponent } from "./control-panel-warning/control-panel-warning.component";
import { SharedModule } from "../shared/shared.module";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

@NgModule({
    imports: [CommonModule, RouterModule, SharedModule, BrowserAnimationsModule],
    declarations: [
        SidebarComponent,
        ControlPanelPageComponent,
        ControlPanelModalComponent,
        ControlPanelWarningComponent,
    ],
    exports: [ControlPanelPageComponent, ControlPanelModalComponent, ControlPanelWarningComponent],
})
export class ControlPanelModule {}
