/* eslint-disable @angular-eslint/no-output-on-prefix */
import { booleanAttribute, Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "app-button-modal",
    templateUrl: "./button-modal.component.html",
    styleUrls: ["./button-modal.component.scss"],
})
export class ButtonModalComponent {
    @Input({ required: true })
    title: string;

    @Input()
    subtitle: string;

    @Input({ transform: booleanAttribute })
    hideButtons = false;

    @Input({ transform: booleanAttribute })
    disableButtons = false;

    @Input()
    buttons: ButtonModal.Buttons;

    @Input()
    route: string;

    @Input({ required: true, transform: booleanAttribute })
    allowExit: boolean;

    @Output()
    alternativeCloseModal: EventEmitter<any> = new EventEmitter();

    public closeModal = (source?: string) => {
        if (this.alternativeCloseModal.observed) {
            this.alternativeCloseModal.emit();
            return;
        }
        if (source && !this.allowExit) {
            return;
        }

        if (this.route) {
            this.router
                .navigateByUrl("/RefrshComponent", { skipLocationChange: true })
                .then(() => this.router.navigate([`/${this.route}`]));
        } else {
            this.router.navigate([{ outlets: { modal: null } }]);
        }
    };

    constructor(private router: Router) {}

    get showButtons() {
        return this.hideButtons || typeof this.buttons === "undefined"
            ? false
            : this.buttons.length > 0;
    }
}
