<app-button-modal
    title="Retour Pakket"
    subtitle="Let op: Bij het bevestigen van deze actie wordt er een nieuw pakket aangemaakt met het type retourpakket."
    [buttons]="buttons"
    allowExit="true"
>
    <form #packageForm="ngForm">
        <div *ngIf="package" id="print-label">
            <div id="packageDetails">
                <div class="senderInfo">
                    <ul>
                        <li>
                            <h4>Verzender</h4>
                        </li>
                        <li>{{ package.sender | fullName }}</li>
                        <li>
                            {{ package.sender.streetname }} {{ package.sender.houseNumber }}
                            {{ package.sender.houseNumberExtra }}
                        </li>
                        <li>{{ package.sender.addressExtra }}</li>
                        <li>{{ package.sender.postcode }}</li>
                        <li>{{ package.sender.city }}</li>
                        <li>
                            <a href="tel:{{ package.sender.phone }}"> {{ package.sender.phone }}</a>
                        </li>
                        <li>
                            <a href="mailto:{{ package.sender.email }}">{{
                                package.sender.email
                            }}</a>
                        </li>
                    </ul>
                </div>
                <div class="recipientInfo">
                    <ul>
                        <li>
                            <h4>Ontvanger</h4>
                        </li>
                        <li>
                            <i
                                *ngIf="package.recipient.companyName !== ''"
                                class="far fa-building"
                            ></i>
                            {{ package.recipient.companyName }}
                        </li>
                        <li>{{ package.recipient | fullName }}</li>
                        <li>
                            {{ package.recipient.streetname }}
                            {{ package.recipient.houseNumber }}
                            {{ package.recipient.houseNumberExtra }}
                        </li>
                        <li>{{ package.recipient.addressExtra }}</li>
                        <li>{{ package.recipient.postcode }}</li>
                        <li>{{ package.recipient.city }}</li>
                        <li>{{ package.recipient.country }}</li>
                        <li>
                            <a href="tel:{{ package.recipient.phone }}">
                                {{ package.recipient.phone }}</a
                            >
                        </li>
                        <li>
                            <a href="mailto:{{ package.recipient.email }}">
                                {{ package.recipient.email }}</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </form>
</app-button-modal>
