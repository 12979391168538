<app-button-modal
    title="Verwijder Pakket"
    [buttons]="buttons"
    [hideButtons]="!package"
    allowExit="true"
>
    <form #packageForm="ngForm">
        <div *ngIf="package" id="delete-package">
            <h2>
                <span>Waarschuwing:</span> Weet je zeker dat je onderstaand pakket wilt verwijderen?
            </h2>
            <div class="packageInfo">
                <h2>
                    <a
                        href="https://www.viatim.nl/trackentrace-status/?tracktrace={{
                            package.tracktrace
                        }}&postcode={{ package.recipient.postcode }}"
                        target="_blank"
                    >
                        {{ package.tracktrace }}
                        <i class="far fa-share fa-xs" data-fa-transform="up-2"></i
                    ></a>
                </h2>
            </div>
            <div id="packageDetails">
                <div class="senderInfo">
                    <ul>
                        <li>
                            <h4>Verzender</h4>
                        </li>
                        <li>{{ package.sender | fullName }}</li>
                        <li>
                            {{ package.sender.streetname }} {{ package.sender.houseNumber }}
                            {{ package.sender.houseNumberExtra }}
                        </li>
                        <li>{{ package.sender.addressExtra }}</li>
                        <li>{{ package.sender.postcode }}</li>
                        <li>{{ package.sender.city }}</li>
                        <li>
                            <a href="tel:{{ package.sender.phone }}"> {{ package.sender.phone }}</a>
                        </li>
                        <li>
                            <a href="mailto:{{ package.sender.email }}">{{
                                package.sender.email
                            }}</a>
                        </li>
                    </ul>
                </div>
                <div class="recipientInfo">
                    <ul>
                        <li>
                            <h4>Ontvanger</h4>
                        </li>
                        <li>
                            <i
                                *ngIf="package.recipient.companyName !== ''"
                                class="far fa-building"
                            ></i>
                            {{ package.recipient.companyName }}
                        </li>
                        <li>{{ package.recipient | fullName }}</li>
                        <li>
                            {{ package.recipient.streetname }}
                            {{ package.recipient.houseNumber }}
                            {{ package.recipient.houseNumberExtra }}
                        </li>
                        <li>{{ package.recipient.addressExtra }}</li>
                        <li>{{ package.recipient.postcode }}</li>
                        <li>{{ package.recipient.city }}</li>
                        <li>{{ package.recipient.country }}</li>
                        <li>
                            <a href="tel:{{ package.recipient.phone }}">
                                {{ package.recipient.phone }}</a
                            >
                        </li>
                        <li>
                            <a href="mailto:{{ package.recipient.email }}">
                                {{ package.recipient.email }}</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </form>
</app-button-modal>
