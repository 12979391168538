import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { StatisticsComponent } from "./statistics/statistics.component";
import { ViatimNewsModalComponent } from "./dashboard/modals/viatim-news-modal/viatim-news-modal.component";
import { ViatimPatchNotesModalComponent } from "./dashboard/modals/viatim-patch-notes-modal/viatim-patch-notes-modal.component";
import { ViatimModalComponent } from "./dashboard/modals/viatim-modal/viatim-modal.component";
import { ViatimStatisticsModalComponent } from "./dashboard/modals/viatim-statistics-modal/viatim-statistics-modal.component";

import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";

import { AuthGuard } from "./services/auth.guard";
import { CreateUserComponent } from "./create-user/create-user.component";
import { BulkStatusModalComponent } from "./dashboard/modals/bulk-status-modal/bulk-status-modal.component";
import { OldInvoicingExportModalComponent } from "./dashboard/modals/old-invoicing-export-modal/old-invoicing-export-modal.component";
import { ReturnLabelsModalComponent } from "./dashboard/modals/return-labels-modal/return-labels-modal.component";

import { LoginComponent } from "./log-in/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ForgotPasswordSuccessComponent } from "./forgot-password-success/forgot-password-success.component";
import { PasswordResetComponent } from "./password-reset/password-reset.component";
import { PasswordResetSuccessComponent } from "./password-reset-success/password-reset-success.component";
import { AccountManagementComponent } from "./account-management/account-management.component";
import { AccountManagementEditComponent } from "./account-management/account-management-edit/account-management-edit.component";
import { SyncLabelsComponent } from "./dashboard/modals/sync-labels/sync-labels.component";

const appRoutes: Routes = [
    {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "statistics",
        component: StatisticsComponent,
        canActivate: [AuthGuard],
    },
    // Modals
    {
        path: "account-management",
        component: AccountManagementComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "account-management/edit",
        component: AccountManagementEditComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "dashboard(modal:create-user)",
        component: CreateUserComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "create-user",
        component: CreateUserComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "news",
        component: ViatimNewsModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "patch-notes",
        component: ViatimPatchNotesModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "bulk-status",
        component: BulkStatusModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "old-invoicing-export",
        component: OldInvoicingExportModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "return-labels",
        component: ReturnLabelsModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "tip-webshop",
        component: ViatimModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "statistics",
        component: ViatimStatisticsModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "sync-labels",
        component: SyncLabelsComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    // { path: 'support', component: SupportComponent, canActivate: [AuthGuard] },
    {
        path: "login",
        component: LoginComponent,
    },
    {
        path: "login/forgot",
        component: ForgotPasswordComponent,
    },
    {
        path: "login/forgot/success",
        component: ForgotPasswordSuccessComponent,
    },
    {
        path: "login/reset",
        component: PasswordResetComponent,
    },
    {
        path: "login/reset/success",
        component: PasswordResetSuccessComponent,
    },
    {
        path: "",
        component: DashboardComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "unauthorized",
        component: UnauthorizedComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "**",
        component: PageNotFoundComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { enableTracing: false })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
